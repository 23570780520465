import React from 'react';
//import img from '../images/web.svg'; 
//import img2 from '../images/app.svg';
//import img3 from '../images/hosting.svg';
//import img4 from '../images/consultation.svg';
import { Link } from 'react-router-dom';
const Services = () => {
    const syllabusUrl = '/syllabus.pdf';
    const previousYearTamilUrl = '/previousquestionstamil.pdf'
    const previousYearGeneralUrl = '/PreviousYearQuestionGeneral.pdf'
    return (
        <div id="services" className="bg-gray-100 py-12 mt-8 w-full lg:py-24" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">TNPSC</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Welcome to TNPSC Coaching Center, offering both online and offline classes for Tamil Nadu Public Service Commission (TNPSC) exams. Explore our services below:</h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />*/}
                                <h2 className="font-semibold my-4 text-2xl text-center">Group IV</h2>
                                <p>Are you preparing for the TNPSC Group 4 and VAO exams? Access previous year's question papers for TNPSC Group 4 through this article.
                                    These TNPSC Group 4 Previous Year Question Papers are invaluable resources that provide insights into the question patterns and help candidates prepare effectively.
                                    You can download the TNPSC Group 4 Previous Year Question Papers in PDF format below and kickstart your exam preparation today!
                                </p>



                                <p> Start solving these papers to familiarize yourself with the exam format, time management, and types of questions asked. Good luck with your preparation!</p>
                                <h2 className="font-semibold my-4 text-2xl text-center">Exam Pattern</h2>
                                <table className="w-full table-auto">
                                    <thead>
                                        <tr>
                                            <th className="border border-gray-400 px-4 py-2">Subject</th>
                                            <th className="border border-gray-400 px-4 py-2">Number of Questions</th>
                                            <th className="border border-gray-400 px-4 py-2">Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border border-gray-400 px-4 py-2">Part A: Tamil Eligibility-cum-Scoring exam </td>
                                            <td className="border border-gray-400 px-4 py-2">100 questions</td>
                                            <td className="border border-gray-400 px-4 py-2">150 marks</td>

                                        </tr>
                                        <tr>
                                            <td className="border border-gray-400 px-4 py-2">Part B: General Studies + Aptitude & Mental Ability Test </td>
                                            <td className="border border-gray-400 px-4 py-2">75 questions <br></br> + 25 questions</td>
                                            <td className="border border-gray-400 px-4 py-2">150 marks</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-gray-400 px-4 py-2">Total</td>
                                            <td className="border border-gray-400 px-4 py-2">200 questions</td>
                                            <td className="border border-gray-400 px-4 py-2">300 marks</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                            </div>
                        </div>


                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">

                                <h2 className="font-semibold my-4 text-2xl ">Syllabus for Group IV and VAO</h2>
                                <p className="text-md font-medium">
                                    Start solving these papers to familiarize yourself with the exam format, time management, and types of questions asked. Good luck with your preparation! </p>
                                <br />
                                <a href={syllabusUrl} download>
                                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                                        Download syllabus
                                    </button>
                                </a>



                                <br></br>
                                <br></br>
                                <a href={previousYearTamilUrl} download>
                                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                                        Previous Year Question Paper 2022 General Tamil
                                    </button>
                                </a>
                                <br></br>
                                <br></br>
                                <a href={previousYearGeneralUrl} download>
                                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                                        Previous Year Question Paper 2022 General Studies
                                    </button>
                                </a>
                                <br></br>
                                <Link to={{
                                    pathname: "/signup",
                                }}
                                    state={{
                                        planCost: "10000",
                                        planName: "TNPSC",
                                        planDuration: "2 months",
                                    }}
                                    exact className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Apply Now
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />

        </div>
    )
}

export default Services;