import React from 'react';

const SubInspector = () => {
    const tnusrbSISyllabusUrl = '/sisyllabus.pdf';
    return (
        <div className='md:mt-28 '>
            <div style={{ margin: '40px' }}>
                <div className="px-0" data-aos-delay="600">
                    <div className="m-2  ">
                        <h2 className="font-semibold my-4 text-2xl pt-6">TNUSRB SI Recruitment Process</h2>
                        <p>The selection phases for the recruitment are:</p>
                        <ul className="list-disc ml-10">
                            <li>Written Examination (Part II Main Written examination)</li>
                            <li>Physical Measurement Test (PMT)</li>
                            <li>Endurance Test</li>
                            <li>Physical Efficiency Test (PET)</li>
                            <li>Document Verification</li>
                            <li>Final Provisional Select List</li>
                        </ul>
                        <br />
                        <h2 className="font-semibold my-4 text-2xl "> Overview of TNUSRB SI(TALUK, AR & TSP)</h2>
                        <p>The Written Examination for the selection of Sub-Inspectors of Police (Taluk, AR & TSP) will
                            have two parts:</p>
                        <h2 className="font-semibold my-4 text-2xl">Part I</h2>
                        <h2 className="font-semibold my-4 text-2xl">Tamil Language Eligibility Test [Common for Open Quota and Departmental Quota Candidates] :</h2>
                        <ul className="list-disc ml-10">
                            <li>The Tamil Language Eligibility Test is qualifying in nature. It will consist of objective type questions of 1 mark each.</li>
                            <li>In respect of 20% Departmental candidates, the Tamil Language eligibility Test will be commonly conducted along with those who are applying in open quota.</li>
                            <li> The candidates who have applied under BOTH Open and Departmental category will have towrite the commonly conducted Tamil Language Eligibility Test only once.</li>
                        </ul>
                        <br />
                        <h2 className="font-semibold my-4 text-2xl">Part II</h2>
                        <h2 className="font-semibold my-4 text-2xl">TNUSRB SI Exam Pattern for Open Quota</h2>
                        <table className="w-full content-center table-auto ">
                            <thead>
                                <tr>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2 ">S.No</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Subject</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2"> Number of Questions</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2"> Total Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">1 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">General Knowledge </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">80 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">40 </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">2 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">Psychology Test </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">60 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">30 </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2"></td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">Total</td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">140</td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">70</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h2 className="font-semibold my-4 text-2xl">Part II</h2>
                        <h2 className="font-semibold my-4 text-2xl">TNUSRB SI Exam Pattern for Departmental Quota</h2>
                        <table className="w-full content-center table-auto">
                            <thead>
                                <tr>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2 ">S.No</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Subject</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2"> Number of Questions</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2"> Total Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">1 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">General Knowledge </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">30 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">15 </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">2 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">Psychology Test </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">140 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">70 </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2"></td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">Total</td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">170</td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">85</td>
                                </tr>
                            </tbody>
                        </table>
                        <h2 className="font-semibold my-4 text-2xl">Physical Measurement Test</h2>
                        <table className="w-full content-center table-auto">
                            <thead>
                                <tr>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Candidates</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Category</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Height</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Chest(in cms)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">Male </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">General/BC </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">170 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">81-86 </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2"> </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">SC/ST </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">167 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">- </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">Female </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">General/BC </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">159 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">NA </td>

                                </tr>
                                <tr>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2"> </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">SC/ST </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">157 </td>
                                    <td className="border border-gray-400 lg:px-4 lg:py-2">NA </td>

                                </tr>
                            </tbody>
                        </table>
                        <h2 className="font-semibold my-4 text-2xl">Endurance Test</h2>
                        <table className="w-full content-center table-auto">
                            <thead>
                                <tr>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">S.No</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Category</th>
                                    <th className="border border-gray-400 lg:px-4 lg:py-2">Task</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2">1 </td>
                                    <td className="border border-gray-400 px-4 py-2">Men</td>
                                    <td className="border border-gray-400 px-4 py-2">1500 meter run in 7mins </td>


                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2"> 2</td>
                                    <td className="border border-gray-400 px-4 py-2"> Women</td>
                                    <td className="border border-gray-400 px-4 py-2">400 meter run in 2mins and 30 sec</td>
                                </tr>
                                <tr>
                                    <td className="border border-gray-400 px-4 py-2">3</td>
                                    <td className="border border-gray-400 px-4 py-2">ESM</td>
                                    <td className="border border-gray-400 px-4 py-2">1500 meter run in 8 mins </td>

                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <a href={tnusrbSISyllabusUrl} download>
                            <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                                TNUSRB SI Exam Syllabus
                            </button>
                        </a>

                    </div>
                </div>
            </div>
        </div>
    );
};



export default SubInspector;
