import React from 'react';
import { Link } from 'react-router-dom';

const ComputerCourse = () => {
  const aeSyllabusUrl = '/AESyllabus.pdf';
  const syllabusUrl = '/syllabus.pdf';
  const previousYearTamilUrl = '/previousquestionstamil.pdf'
  const previousYearGeneralUrl = '/PreviousYearQuestionGeneral.pdf'
  return (
    <div className='md:mt-28 '>
      {/* 
    <div style={{ margin: '40px' }}>
      <div className="px-12" data-aos-delay="600">
        <div className="m-2 text-justify ">
          <h2 className="font-semibold my-4 text-2xl ">Computer Course for +2 Completed students</h2>
          <p>Paper 1- requires candidates to choose any one of the following computer course(<span className='text-dark font-bold'>C, C++, JAVA, PYTHON, JAVASCRIPT</span>) in which they have acquired their educational qualification (Objective Type).</p>
          <p> Paper 2 
          </p>
          <ul  className="list-disc ml-10">
          <li>Part A-Coding Test (Objective Type)</li>
            <li>Part B-General coding Studies (Objective Type)</li>
          </ul>
          <br />
          <h2 className="font-semibold my-4 text-2xl "> Eligibility Criteria</h2>
          <p>Candidates with +2 Students  disciplines such as maths biology, computer sciennce and commerce and bio maths, textiles are eligible to apply for the yaazhtech training. </p>
          <h2 className="font-semibold my-4 text-2xl "> Overview of Computer Course</h2>
          <p> The examination format comprises a written online test featuring objective-type questions, divided into two papers. Paper I will focus on subjects pertinent to the respective engineering discipline, with a duration of 3 hours. Conversely, Paper II will encompass General Studies questions, with candidates allotted 2 hours for completion.
          </p>
          <br />
          <h2 className="font-semibold my-4 text-2xl">Exam Pattern</h2>
          <table className="w-50% content-center table-auto">
            <thead>
              <tr>
                <th className="border border-gray-400 px-4 py-2">Paper</th>
                <th className="border border-gray-400 px-4 py-2">Number of Questions</th>
                <th className="border border-gray-400 px-4 py-2"> Total Marks</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-400 px-4 py-2">Paper-1 (computer course) </td>
                <td className="border border-gray-400 px-4 py-2">200 </td>
                <td className="border border-gray-400 px-4 py-2">300 </td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">Paper-2 (coding(which Language you can choosed))
                <br></br>
                  Part-A
                  100 Questions /150 Marks </td>
                <td className="border border-gray-400 px-4 py-2">100</td>
                <td className="border border-gray-400 px-4 py-2">Note:
                  Minimum qualifying marks – 60 marks (40% of 150). Marks secured in Part-A of Paper-2 will not be taken into account for the ranking.</td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">Part-B (General Mathematics Studies and Mental Ability) </td>
                <td className="border border-gray-400 px-4 py-2">100</td>
                <td className="border border-gray-400 px-4 py-2">150</td>
              </tr>
              <tr>
                <td className="border border-gray-400 px-4 py-2">Total</td>
                <td className="border border-gray-400 px-4 py-2"></td>
                <td className="border border-gray-400 px-4 py-2">450</td>
              </tr>
            </tbody>
          </table>
          <br />
          <a href={aeSyllabusUrl} download>
                              <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                               Exam Syllabus
                        </button>
                    </a>
        </div>
      </div>
    </div>
    */}
      <div id="services" className="bg-gray-100 py-12 " >
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Computer Course</h2>

            <div className='flex justify-center'>
              <div className='w-24 border-b-4 border-blue-900'></div>
            </div>
            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Welcome to Computer Course Coaching Center, offering both online and offline classes for Certification. Explore our services below:</h2>
          </div>

          <div className="px-12" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />*/}
                  <h2 className="font-semibold my-4 text-2xl text-center">Computer Course</h2>
                  <p>When training +2 students (high school seniors or equivalent) in Java programming, it's essential to balance depth and simplicity. Here's a tailored curriculum for such students:
                  </p>
                  <p>A computer course refers to a structured program of study that focuses on various aspects of computer science, information technology, or specific areas within the realm of computing. These courses are designed to educate individuals on how to use computers, software applications, programming languages, and IT systems effectively. Computer courses can vary widely in terms of their content, duration, level of difficulty, and intended audience.</p>
                  <h2 className="font-semibold my-4 text-2xl "> Eligibility Criteria</h2>
                  <p>Candidates with +2, 10th Students</p>
                  <p className=' font-semibold text-lg'>Computer course Fee:5000, Duration: 2 months</p>



                  <br />
                  <Link
                    to={{
                      pathname: "/signup",
                    }}
                    state={{
                      planCost: "5000",
                      planName: "Computer Course",
                      planDuration: "2 months",
                    }}
                    exact



                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                    Apply Now
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                  </Link>
                </div>
              </div>


              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">

                  <h2 className="font-semibold my-4 text-2xl ">Syllabus for Computer Course</h2>
                  <p className="text-md font-medium mb-2">
                    Programming courses teach programming languages and coding concepts. They can range from introductory courses for beginners (e.g., Python, Java, C++) to more advanced courses focusing on specific application areas like web development, mobile app development.      <br />
                  </p>
                  {/* <a href={syllabusUrl} download>
                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                      Download syllabus
                    </button>
                  </a>
                  <br></br>
                  <br></br>
                  <a href={previousYearTamilUrl} download>
                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                      Previous Year Question Paper 2022 General Coding
                    </button>
                  </a>
                  <br></br>
                  <br></br>
                  <a href={previousYearGeneralUrl} download>
                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                      Previous Year Question Paper 2022 General Coding Studies
                    </button>
                  </a> */}
                  <br></br>
                  <ul class="list-disc ml-10 leading-9">
                    <li>
                      Computer science encompasses the study of algorithms, data structures, and the principles of programming languages.
                    </li>
                    <li>It plays a crucial role in the development of software, hardware, and network systems that power modern technology.</li>
                    <li>The field includes specialized areas such as artificial intelligence, cybersecurity, and data analytics.</li>
                    <li>Computer science drives innovation in various industries, from healthcare to entertainment, enhancing efficiency and creating new possibilities.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />

      </div>
    </div>
  );
};

export default ComputerCourse;
