const prod = {
  url: {
    API_BASE_URL: 'https://www.darwintnpsc.com:8084',
    API_BASE_URL_ACTUATOR: 'https://www.darwintnpsc.com:8084/manage',
    API_GATE_URL: 'https://api.phonepe.com/apis/hermes',
    API_REDIRECT_URL: 'https://yaazhtech.com/services',
    API_CALLBACK_URL: 'https://www.yaazhtech.com:8083/public/paymentUpdate',
  },
};

const dev = {
  url: {
    API_BASE_URL: '//localhost:8084',
    API_BASE_URL_ACTUATOR: '//localhost:8084/manage',
    API_GATE_URL: 'https://api.phonepe.com/apis/hermes',
    API_REDIRECT_URL: 'https://yaazhtech.com/services',
    API_CALLBACK_URL: 'https://yaazhtech.com/services/paymentUpdate',

  },
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;

export const ACCESS_TOKEN = 'accessToken';
export const ADMIN_TOKEN = 'adminToken';
export const EMPLOYEE_TOKEN = 'employeeToken';
export const CLIENT_TOKEN = 'clientToken';
export const FINANCIER_TOKEN = 'financierToken';
export const HR_TOKEN = 'hrToken';
export const COOKIE_PREF = 'cookie_pref';
export const CONTENT_ENDPOINTS = 'CONTENT_ENDPOINTS';
export const ADMIN = "ADMIN";
export const USER = "USER"; // when apply for anything basic access
export const HR = "HR";
export const EMPLOYEE = "EMPLOYEE"; // when you become probation or hire
export const EMPLOYER = "EMPLOYER";
export const CLIENT = "CLIENT";
export const PUPIL = "PUPIL";
export const FINANCIER = "FINANCIER";
export const Language ='en';
export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 16;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const MOBILE_EXACT_LENGTH = 10;
export const NAME_MIN_LENGTH = 1;
export const AADHAR_EXACT_LENGTH = 12;
export const PAN_EXACT_LENGTH = 10;
