import React from 'react';

const AssistantEngineer = () => {
  const aeSyllabusUrl = '/AESyllabus.pdf';
  return (
    <div className='md:mt-28 '>
      <div style={{ margin: '40px' }}>
        <div className="px-0" data-aos-delay="600">
          <div className="m-2 ">
            <h2 className="font-semibold my-4 text-2xl pt-6">TNPSC Group Combined Engineering Service (CESE) Exam Pattern</h2>
            <p>Paper 1- requires candidates to choose any one of the following subjects in which they have acquired their educational qualification (Objective Type).</p>
            <p> Paper 2
            </p>
            <ul className="list-disc ml-10">
              <li>Part A-Tamil Eligibility Test (Objective Type)</li>
              <li>Part B-General Studies (Objective Type)</li>
            </ul>
            <br />
            <h2 className="font-semibold my-4 text-2xl "> Eligibility Criteria</h2>
            <p>Candidates with degrees in engineering disciplines such as electrical, mechanical, civil, agriculture, electronics, production, and telecommunications are eligible to apply for the exam. </p>
            <h2 className="font-semibold my-4 text-2xl "> Overview of TNPSC Group Combined Engineering Service (CESE)</h2>
            <p> The examination format comprises a written test featuring objective-type questions, divided into two papers. Paper I will focus on subjects pertinent to the respective engineering discipline, with a duration of 3 hours. Conversely, Paper II will encompass General Studies questions, with candidates allotted 2 hours for completion.
            </p>
            <br />
            <h2 className="font-semibold my-4 text-2xl">Exam Pattern</h2>
            <table className="w-50% content-center table-auto">
              <thead>
                <tr>
                  <th className="border border-gray-400 ls:px-4 py-2">Paper</th>
                  <th className="border border-gray-400 px-4 py-2">Number of Questions</th>
                  <th className="border border-gray-400 px-4 py-2"> Total Marks</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 ls:px-4 py-2">Paper-1 (Engineering Domain) </td>
                  <td className="border border-gray-400 px-4 py-2">200 </td>
                  <td className="border border-gray-400 px-4 py-2">300 </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 py-2">Paper-2 (Tamil Eligibility Test SSLC Std)
                    <br></br>
                    Part-A
                    100 Questions /150 Marks </td>
                  <td className="border border-gray-400 px-4 py-2">100</td>
                  <td className="border border-gray-400 ls:px-4 py-2">Note:
                    Minimum qualifying marks – 60 marks (40% of 150). Marks secured in Part-A of Paper-2 will not be taken into account for the ranking.</td>
                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 py-2">Part-B (General Studies and Mental Ability) </td>
                  <td className="border border-gray-400 px-4 py-2">100</td>
                  <td className="border border-gray-400 px-4 py-2">150</td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">Total</td>
                  <td className="border border-gray-400 px-4 py-2"></td>
                  <td className="border border-gray-400 px-4 py-2">450</td>
                </tr>
              </tbody>
            </table>
            <br />
            <a href={aeSyllabusUrl} download>
              <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                Exam Syllabus
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssistantEngineer;
