import React from "react";
import { Link } from "react-router-dom";

const Counselling = () => {
  const aeSyllabusUrl = "/AESyllabus.pdf";
  const syllabusUrl = "/syllabus.pdf";
  //const previousYearTamilUrl = "/previousquestionstamil.pdf";
  //const previousYearGeneralUrl = "/PreviousYearQuestionGeneral.pdf";
  return (
    <div className="md:mt-28 ">
      {/*
      <div style={{ margin: "40px" }}>
        <div className="px-12" data-aos-delay="600">
          <div className="m-2 text-justify ">
            <h2 className="font-semibold my-4 text-2xl ">
              TNEA 2024 Exam: Registration, Eligibility, Counselling Dates, Rank
              List, Cutoff
            </h2>
            <p>
              The TNEA exam is conducted for aspiring students who wish to take
              admission in Engineering programs at the UG level in different
              colleges of Tamil Nadu. The notification for the same will be
              tentatively released in June 2023.
            </p>

            <br />
            <h2 className="font-semibold my-4 text-2xl ">
              {" "}
              Eligibility Criteria
            </h2>
            <p>
              Candidates with +2 Students disciplines such as all the candiate
              are eligible{" "}
            </p>
            <h2 className="font-semibold my-4 text-2xl ">
              {" "}
              Overview of TNEA(Tamil Nadu Engineering Admissions. )
            </h2>
            <p>
              {" "}
              The examination format comprises a written online test featuring
              objective-type questions, divided into two papers. Paper I will
              focus on subjects pertinent to the respective engineering
              discipline, with a duration of 3 hours. Conversely, Paper II will
              encompass General Studies questions, with candidates allotted 2
              hours for completion.
            </p>
            <br />
            <h2 className="font-semibold my-4 text-2xl">Exam Highlight</h2>
            <table className="w-50% content-center table-auto">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">
                    Particular
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Details information about TNEA
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Name of the Exam
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Official Website{" "}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Frequency of Exam/Counselling{" "}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Mode of Admission{" "}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Participating Colleges{" "}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Application Mode
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Application Fee
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Tamil Nadu Engineering Admissions (TNEA)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    www.tneaonline.org{" "}
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Once a year
                  </td>
                  <td className="border border-gray-400 px-4 py-2">Online</td>
                  <td className="border border-gray-400 px-4 py-2">539</td>
                  <td className="border border-gray-400 px-4 py-2">Online</td>
                  <td className="border border-gray-400 px-4 py-2">
                    General- INR 500
                    <br />
                    SC/ST/SCA- INR 250
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h2 className="font-semibold my-4 text-2xl">TNEA 2024 Dates</h2>
            <table className="w-50% content-center table-auto">
              <thead>
                <tr>
                  <th className="border border-gray-400 px-4 py-2">
                    Particular
                  </th>
                  <th className="border border-gray-400 px-4 py-2">
                    Tentative Dates
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    Start date of online TNEA Registration
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Last date of online TNEA Registration
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Assigning Random Number
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Declaration of TNEA Rank List
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Grievances Address
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Special TNEA Counselling (For reserved categories)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Commencement of TNEA General Counselling
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    Supplementary TNEA Counselling (Online)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    SCA to SC Counselling (Online)
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    End of TNEA Counselling
                  </td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">
                    May 4, 2024
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    June 4, 2024
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    June 7, 2024
                  </td>
                  <td className="border border-gray-400 px-4 py-2">
                    July 12, 2024
                  </td>
                  <td className="border border-gray-400 px-4 py-2">TBA</td>
                  <td className="border border-gray-400 px-4 py-2">TBA</td>
                  <td className="border border-gray-400 px-4 py-2">TBA</td>
                  <td className="border border-gray-400 px-4 py-2">TBA</td>
                  <td className="border border-gray-400 px-4 py-2">TBA</td>
                  <td className="border border-gray-400 px-4 py-2">TBA</td>
                </tr>
              </tbody>
            </table>
            <br />
            <a href={aeSyllabusUrl} download>
              <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                Exam Syllabus
              </button>
            </a>
          </div>
        </div>
      </div>
      */}
      <div id="services" className="bg-gray-100 py-12">
        <section data-aos="zoom-in-down">
          <div className="my-4 py-4">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
              Engineering Counselling
            </h2>

            <div className="flex justify-center">
              <div className="w-24 border-b-4 border-blue-900"></div>
            </div>
         
          </div>

          <div className="px-12" data-aos="fade-down" data-aos-delay="600">
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5">
              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />*/}
                  <h2 className="font-semibold my-4 text-2xl text-center">
                   Counselling consulation
                  </h2>
                  <p>
                  Candidates wishing to appear for the TNEA 2024 counselling must fulfil the eligibility criteria. The TNEA eligibility criteria is laid down by Anna University, Chennai. The eligibility criteria to take the TNEA 2024 exam is divided into Nativity & Academic Qualification. Candidates can check the detailed eligibility requirements below:
                  </p>

                  <ul  className="list-disc ml-10 leading-5">
          <li>we will identify the college and course as per a cut off mark
</li>
            <li>we will assists to you fill online counselling application for the all the rounds untill secure the allotment.
</li>
            <li>Note:
 final selection of the college is your decision we will justify guide you as per your cutoff mark we are not responsible for your final choice.
 </li>
 <li className=" font-semibold text-base">Application Fee:500, Consulation Fee:1000 including GST, Duration: 1 month</li>
            </ul>

                  <br />
                  <Link
                  to={{
                    pathname: "/signup",
                  }}
                  state={{
                    planCost: "1500",
                    planName: "Counselling",
                    planDuration: "1 month",
                  }}
                  exact
                    className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-3 py-2 my-4 text-base shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                  >
                    Apply Now
                    <svg
                      className="w-4 h-4 ml-1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <h2 className="font-semibold my-4 text-2xl ">
                    Counselling for the Engineering Department
                  </h2>
                  <ul  className="list-disc ml-10 leading-9">
          <li>Step 1: Registration. Candidates had to register for the counselling process. ..</li>
            <li>Step 2: Assigning a Random Number. The registered candidates are assigned a random number</li>
            <li>Step 3: Certificate verification. </li>
            <li>Step 4: Publication of Rank List. ...</li>
            <li>Step 5: Counselling</li>
          </ul>
          <br />
                  <br />
                  <a href={syllabusUrl} download>
                    <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                      Download Counselling
                    </button>
                  </a>

                  <br></br>
                  <br></br>
                
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
      </div>
    </div>
  );
};

export default Counselling;
