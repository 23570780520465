import React from 'react';
//import { Link } from 'react-router-dom';
//import img from '../images/web.svg';
//import img2 from '../images/app.svg';
//import img3 from '../images/hosting.svg';
//import img4 from '../images/consultation.svg';
const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                {/* <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />*/}
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Online and Offline classes</h4>
                                <ul  className="list-disc ml-10">
                                    <li>Gain a competitive edge with our comprehensive exam preparation, featuring expert-led online sessions and interactive offline classes designed for in-depth learning and skill development. </li>
                                    <li>Explore our website for comprehensive course details and easy enrollment.</li>
                                    <li>We empower students for success in competitive exams through our specialized coaching programs.</li>
                                </ul>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Mock Tests</h4>
                                <ul  className="list-disc ml-10">
                                    <li>we organize weekly mock tests as part of our comprehensive exam preparation program. </li>
                                    <li> These mock tests are designed to closely simulate the actual exam environment, allowing students to assess their knowledge, identify areas for improvement, and enhance their exam-taking skills.</li>
                                    <li>Our experienced faculty meticulously review the mock test results, providing detailed feedback and personalized guidance to help students strengthen their understanding and boost their confidence for the real exam.</li>
                                </ul>
                                
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Study Material</h4>
                                <ul  className="list-disc ml-10">
                                    <li>we offer a wide range of study materials, including interactive e-books, video lectures, curated notes, and practice worksheets, all meticulously designed to cater to different learning styles and enhance students' understanding and retention of key concepts. </li>
                                    </ul>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;