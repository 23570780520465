import axios from 'axios';
import {ACCESS_TOKEN, config } from '../config/Config';
const backendUrl = "idp";
const actuator = "manage";
const secureUrl = "secure";
const sendRequest = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  return fetch(options.url, options)
    .then(
      (response) => response.json()
        .then(
          (json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          },
        ),
    );
};
const sendRequestFile = (options) => {
  const headers = new Headers({
    'Content-Type': 'multipart/form-data',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`);
  }

  const defaults = { headers };
  options = { ...defaults, ...options };

  return fetch(options.url, options)
    .then(
      (response) => response.json()
        .then(
          (json) => {
            if (!response.ok) {
              return Promise.reject(json);
            }
            return json;
          },
        ),
    );
};
const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});
export function login(loginRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/${backendUrl}/visual/public/authenticate`,
    method: 'POST',
    body: JSON.stringify(loginRequest),
  });
}

export function signupStep1(signUpRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/${backendUrl}/visual/public/signup-step1`,
    method: 'POST',
    body: JSON.stringify(signUpRequest),
  });
}
export function signupStep2(signUpRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/${backendUrl}/visual/public/signup-step2`,
    method: 'POST',
    body: JSON.stringify(signUpRequest),
  });
}
export function validateEmailOTP(otpValidationRequest) {
  return sendRequest({
    url: `${config.url.API_BASE_URL}/${backendUrl}/visual/public/signup-step2`,
    method: 'POST',
    body: JSON.stringify(otpValidationRequest),
  });
}

export function uploadStatus(videoRequest) {
  return sendRequest({
    url: `${secureUrl}/visual/videos/uploadStatus`,
    method: 'POST',
    body: JSON.stringify(videoRequest),
  });
}

export function fetchVideosByCategory(category) {
  return sendRequest({
    url: `${backendUrl}/visual/public/getVideosByCategory?category=${category}`,
    method: 'GET',
  });
}

export function getVisualData() {
 
  return instance.get(
    `/visual/secure/admin/dashboard/users/`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
