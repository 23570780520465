import React from 'react';

const PoliceConstable = () => {
  const tnusrbSyllabusUrl = '/pcsyllabus.pdf';
  return (
    <div className='md:mt-28'>
      <div style={{ margin: '40px' }}>

        <div className="px-0" data-aos-delay="600">



          <div className="m-2 ">
            <h2 className="font-semibold my-4 text-2xl pt-6">TNUSRB Recruitment Process</h2>
            <p>The selection phases for the recruitment are:</p>
            <ul className="list-disc ml-10">
              <li>Written Examination (Part II Main Written examination)</li>
              <li>Physical Measurement Test (PMT)</li>
              <li>Endurance Test</li>
              <li>Physical Efficiency Test (PET)</li>
              <li>Document Verification</li>
              <li>Final Provisional Select List</li>
            </ul>
            <br />
            <h2 className="font-semibold my-4 text-2xl "> Overview of TNUSRB</h2>

            <p>1)The TNUSRB exam is divided into 02 parts, where Part 1 serves as a qualifying section.</p>
            <p>2)Candidates must achieve a minimum of 40% marks in Part 1.</p>
            <p> 3)The exam includes OMR-based objective-type questions, and each part has a duration of 80 minutes.
            </p>
            <br />
            <h2 className="font-semibold my-4 text-2xl">Exam Pattern</h2>
            <table className="w-full content-center table-auto">
              <thead>
                <tr>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Part</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Paper</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Number of Questions</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2"> Total Marks</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2"> Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">I </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">Tamil </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">80 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">80 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">80 minutes</td>
                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">II </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">General Knowledge </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">45 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">45 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">80 minutes</td>
                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2"> </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">Psychological Exam </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">25 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">25 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2"></td>
                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2"></td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">Total</td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">70</td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">70</td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">160 minutes</td>
                </tr>
              </tbody>
            </table>
            <h2 className="font-semibold my-4 text-2xl">Physical Measurement Test</h2>
            <table className="w-full content-center table-auto">
              <thead>
                <tr>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Candidates</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Category</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Height</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Chest(in cms)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">Male </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">General/BC </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">170 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">81-86 </td>

                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2"> </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">SC/ST </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">167 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">- </td>

                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">Female </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">General/BC </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">159 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">NA </td>

                </tr>
                <tr>
                  <td className="border border-gray-400 ls:px-4 ls:py-2"> </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">SC/ST </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">157 </td>
                  <td className="border border-gray-400 ls:px-4 ls:py-2">NA </td>

                </tr>
              </tbody>
            </table>
            <h2 className="font-semibold my-4 text-2xl">Endurance Test</h2>
            <table className="w-full content-center table-auto">
              <thead>
                <tr>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">S.No</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Category</th>
                  <th className="border border-gray-400 ls:px-4 ls:py-2">Task</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">1 </td>
                  <td className="border border-gray-400 px-4 py-2">Men</td>
                  <td className="border border-gray-400 px-4 py-2">1500 meter run in 7mins </td>


                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2"> 2</td>
                  <td className="border border-gray-400 px-4 py-2"> Women</td>
                  <td className="border border-gray-400 px-4 py-2">400 meter run in 2mins and 30 sec</td>
                </tr>
                <tr>
                  <td className="border border-gray-400 px-4 py-2">3</td>
                  <td className="border border-gray-400 px-4 py-2">ESM</td>
                  <td className="border border-gray-400 px-4 py-2">1500 meter run in 8 mins </td>

                </tr>
              </tbody>
            </table>
            <br />
            <a href={tnusrbSyllabusUrl} download>
              <button className="download-button bg-blue-900 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                TNUSRB PC Exam Syllabus
              </button>
            </a>

          </div>





        </div>


      </div>
    </div>
  );
};

export default PoliceConstable;
