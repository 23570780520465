import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { ACCESS_TOKEN, ADMIN_TOKEN } from "../../config/Config";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const NavLinks = () => {
  const navigate = useNavigate();
  const [showTNPSCDropdown, setShowTNPSCDropdown] = useState(false);
  const [showTNUSRBDropdown, setShowTNUSRBDropdown] = useState(false);
  const [showTrainingDropdown, setShowTrainingDropdown] = useState(false);

  const handleTNPSCDropdownToggle = () => {
    setShowTNPSCDropdown(!showTNPSCDropdown);
    setShowTNUSRBDropdown(false); // Close TNUSRB dropdown if open
    setShowTrainingDropdown(false); // Close training drop
  };

  const handleTNUSRBDropdownToggle = () => {
    setShowTNUSRBDropdown(!showTrainingDropdown);
    setShowTNPSCDropdown(false); // Close TNPSC dropdown if open
    setShowTrainingDropdown(false); // Close TNPSC dropdown if open
  };
  const handleTrainingDropdownToggle = () => {
    setShowTrainingDropdown(!showTrainingDropdown);
    setShowTNPSCDropdown(false); // Close TNPSC dropdown if open
    setShowTNUSRBDropdown(false); // Close TNUSRB dropdown if open
  };
  const logout = () => {
    localStorage.clear();
    localStorage.removeItem(ACCESS_TOKEN);
    toast.success("Logout successfully");
    navigate("/");
  };

  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="https://yaazhtech.com/"
      >
        Home
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/about"
      >
        About
      </HashLink>
      <div className="relative inline-block">
        <button
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 focus:outline-none"
          onClick={handleTNPSCDropdownToggle}
        >
          TNPSC
        </button>
        {showTNPSCDropdown && (
          <div className="absolute z-10 bg-white shadow-md rounded-md mt-1">
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/#services"
              onClick={() => setShowTNPSCDropdown(false)}
            >
              Group IV & VAO
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/assistantEngineer"
              onClick={() => setShowTNPSCDropdown(false)}
            >
              Combined Engineering (CESE)
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/subInspector"
              onClick={() => setShowTNPSCDropdown(false)}
            >
              Sub Inspector
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/policeConstable"
              onClick={() => setShowTNPSCDropdown(false)}
            >
              Police Constable
            </HashLink>
          </div>
        )}
      </div>

      {/* <div className="relative inline-block">
        <button
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 focus:outline-none"
          onClick={handleTNUSRBDropdownToggle}
        >
          TNUSRB
        </button>
        {showTNUSRBDropdown && (
          <div className="absolute z-10 bg-white shadow-md rounded-md mt-1">
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/subInspector"
              onClick={() => setShowTNUSRBDropdown(false)}
            >
              Sub Inspector
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/policeConstable"
              onClick={() => setShowTNUSRBDropdown(false)}
            >
              Police Constable
            </HashLink>
          </div>
        )}
      </div> */}
      <div className="relative inline-block">
        <button
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 focus:outline-none"
          onClick={handleTrainingDropdownToggle}
        >
          Training
        </button>
        {showTrainingDropdown && (
          <div className="absolute z-10 bg-white shadow-md rounded-md mt-1">
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/computerCourse"
              onClick={() => setShowTrainingDropdown(false)}
            >
              Summer computer course
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/communication"
              onClick={() => setShowTrainingDropdown(false)}
            >
              communication training
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/counselling"
              onClick={() => setShowTrainingDropdown(false)}
            >
              Engineering counselling
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/placement"
              onClick={() => setShowTrainingDropdown(false)}
            >
              Placement training
            </HashLink>
            <HashLink
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              smooth
              to="/project"
              onClick={() => setShowTrainingDropdown(false)}
            >
              project training
            </HashLink>
          </div>
        )}
      </div>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/contact#contact"
      >
        Contact Us
      </HashLink>
      {localStorage.getItem(ADMIN_TOKEN) ? (
        <>
          <HashLink
            className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
            smooth
            to="/adminDashboard"
          >
            Dashboard
          </HashLink>
          <HashLink
            className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
            smooth
            onClick={logout}
          >
            Logout
          </HashLink>
        </>
      ) : localStorage.getItem(ACCESS_TOKEN) ? (
        <>
          <HashLink
            className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
            smooth
            onClick={logout}
          >
            Logout
          </HashLink>
        </>
      ) : (
        <>
          {/*  <HashLink
            className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
            smooth
            to="/signup"
          >
            Signup
          </HashLink>
          */}
          <HashLink
            className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
            smooth
            to="/login"
          >
            Login
          </HashLink>
        </>
      )}
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </>
  );
};

export default NavLinks;
